import { B2CPolicy, IEnvironmentVariables } from './IEnvironment';

export const b2cPolicies: B2CPolicy = {
  names: {
    signUpSignIn: 'B2C_1A_Signup_Signin',
    forgotPassword: 'B2C_1A_PasswordReset'
  },
  authorityBaseUrl: 'https://mncportalstage.b2clogin.com/tfp/mncportalstage.onmicrosoft.com'
};

export const environment: IEnvironmentVariables = {
  b2cPolicies: b2cPolicies,
  production: true,
  buildNumber: '_BUILDNUMBER_',
  version: '_VERSION_',
  baseUrl: 'https://usermgmt.portalstg.mynexuscare.com/',
  apiUrl: 'https://api-homehealth.portalstg.mynexuscare.com',
  platformApiUrl: 'https://api-platform.portalstg.mynexuscare.com',
  registryUrl: `https://mnc-stg-api-platform.azurewebsites.net/api/app-registry`,
  authConfig: {
    config: {
      auth: {
        clientId: '496df1ed-7487-4ac2-a958-3fbaeddaedf2',
        postLogoutRedirectUri: 'https://usermgmt.portalstg.mynexuscare.com/login',
        redirectUri: 'https://usermgmt.portalstg.mynexuscare.com/login',
        knownAuthorities: ['mncportalstage.b2clogin.com'],
        authority: `${b2cPolicies.authorityBaseUrl}/${b2cPolicies.names.signUpSignIn}`,
        navigateToLoginRequestUrl: false,
      },
    },
    angularConfig: {
      consentScopes: [
        'https://mncportalstage.onmicrosoft.com/api/api.Write',
        'https://mncportalstage.onmicrosoft.com/api/api.Read',
        'https://mncportalstage.onmicrosoft.com/api/user_impersonation',
      ],
      protectedResourceMap: [
        [
          'https://api-homehealth.portalstg.mynexuscare.com/',
          [
            'https://mncportalstage.onmicrosoft.com/api/api.Write',
            'https://mncportalstage.onmicrosoft.com/api/api.Read',
            'https://mncportalstage.onmicrosoft.com/api/user_impersonation',
          ]
        ],
        [
          'https://api-platform.portalstg.mynexuscare.com/',
          [
            'https://mncportalstage.onmicrosoft.com/api/api.Write',
            'https://mncportalstage.onmicrosoft.com/api/api.Read',
            'https://mncportalstage.onmicrosoft.com/api/user_impersonation',
          ]
        ]
      ]
    }
  }
};
